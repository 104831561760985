import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchEvents as fetchEventsRoutine } from 'store/event/routines';
import { fetchUsersByRole as fetchUsersByRoleRoutine } from 'store/users/routines';
import { Container, Group, Button, SimpleGrid, LoadingOverlay, Text, Alert, Tabs } from '@mantine/core';
import { RootState, EventIndex, UserIndex } from 'types/state';
import { EventCard } from './EventCard';
import { NSWHealthLHD } from 'enums/user-info';
import { Routes, UserRoles } from 'enums';

type Props = {
  isLoading: boolean;
  events: EventIndex;
  fetchEvents: () => void;
  error?: string;
  coordinatorFor?: keyof typeof NSWHealthLHD;
  role: UserRoles;
  users: UserIndex;
  fetchUsersByRole: (role: UserRoles) => void;
};

const CoordinatorDashboardComponent: React.FC<Props> = ({ isLoading, events, fetchEvents, error, coordinatorFor, role, users, fetchUsersByRole }) => {
  useEffect(() => {
    fetchEvents();
    fetchUsersByRole(UserRoles.LEARNER);
  }, []);

  const currentEventList = Object.values(events)
    .filter(e => e.status !== 'ARCHIVED' && e.status !== 'CANCELED')
    .sort((a, b) => new Date(a.instances[0].startsAt).getTime() - new Date(b.instances[0].startsAt).getTime());
  const historicalEventList = Object.values(events)
    .filter(e => e.status === 'ARCHIVED')
    .sort((a, b) => new Date(a.instances[0].startsAt).getTime() - new Date(b.instances[0].startsAt).getTime());

  return (
    <Container size="lg">
      <LoadingOverlay visible={isLoading} />

      {/* <Group position="right" my="xl">
        <Button component={Link} to={Routes.LHD} uppercase variant="filled" color="gray">
          Dashboard
        </Button>
        <Button component={Link} to={Routes.CalendarPage} uppercase variant="default" color="gray">
          Calendar
        </Button>
      </Group> */}

      {error && (
        <Alert color="red" title="Something went wrong" my="lg">
          {error}
        </Alert>
      )}

      {role === UserRoles.LHD_COORDINATOR && !coordinatorFor && (
        <Alert color="red" title="No Associated LHD" my="lg">
          You are registered as a LHD Coordinator but do not have an associated LHD. You will not be able to access any training events.
        </Alert>
      )}

      <Tabs py="xl" defaultValue="current">
        <Tabs.List mb="xl" grow>
          <Tabs.Tab value="current" color="blue">
            Current
          </Tabs.Tab>

          <Tabs.Tab value="historical" color="cyan">
            Historical
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="current">
          <SimpleGrid cols={3}>
            {currentEventList.map(event => (
              <EventCard
                key={event.id}
                event={event}
                role={role}
                users={users}
                coordinatorFor={coordinatorFor}
              />
            ))}
          </SimpleGrid>

          {currentEventList.length === 0 && <Text>No upcoming events.</Text>}
        </Tabs.Panel>
        <Tabs.Panel value="historical">
          <SimpleGrid cols={3}>
            {historicalEventList.map(event => (
              <EventCard
                key={event.id}
                event={event}
                role={role}
                users={users}
                coordinatorFor={coordinatorFor}
              />
            ))}
          </SimpleGrid>

          {historicalEventList.length === 0 && (
            <Text>No historical events.</Text>
          )}
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  events: state.event.events,
  isLoading: state.event.isLoading,
  error: state.event.error,
  coordinatorFor: state.user.coordinatorFor,
  role: state.user.role,
  users: state.users.LEARNER
});

const mapDispatchToProps = {
  fetchEvents: fetchEventsRoutine,
  fetchUsersByRole: fetchUsersByRoleRoutine
};

export const CoordinatorDashboard = connect(mapStateToProps, mapDispatchToProps)(CoordinatorDashboardComponent);
