import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CourseIndex, F2FTypeIndex, RootState, ScormIndex } from 'types/state';
import { fetchScorms as fetchScormsRoutine } from 'store/scorm/routines';
import { fetchCourses as fetchCoursesRoutine } from 'store/course/routines';
import { fetchF2FTypes as fetchF2FTypesRoutine } from 'store/face-to-face/routines';
import { ScormDto } from 'types/api/scorm';
import { AdminRoutes } from 'enums';
import { Table, Title, Group, Button, Alert, LoadingOverlay, Anchor } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

type Props = {
  scorms: ScormIndex;
  isLoading: boolean;
  error?: string;
  fetchScorms?: () => void;
  f2fs: F2FTypeIndex;
  courses: CourseIndex;
  fetchCourses: () => void;
  fetchF2FTypes: () => void;
};

const ScormListComponent: React.FC<Props> = ({ scorms, isLoading, error, fetchScorms, f2fs, courses, fetchCourses, fetchF2FTypes }) => {
  const navigate = useNavigate();

  useEffect(() => {
    fetchCourses();
    fetchF2FTypes();
    fetchScorms();
  }, []);

  const scormList = Object.values(scorms).sort((a, b) => (a.title < b.title ? -1 : 1));

  return (
    <>
      <Group position="right" my="xl">
        <Title order={2} sx={{ flex: '1 !important' }}>
          Scorm Modules
        </Title>
        <Button component={Link} to={AdminRoutes.scormNew} leftIcon={<IconPlus />}>
          Upload New Module
        </Button>
      </Group>

      {error && (
        <Alert title="Error Loading Courses" color="red" my="lg">
          {error}
        </Alert>
      )}

      <Table>
        <thead>
          <tr>
            <th>Module Title</th>
            <th>Used In</th>
          </tr>
        </thead>
        <tbody>
          {scormList.map((scorm: ScormDto) => (
            <tr key={scorm.id} onClick={() => navigate(`${AdminRoutes.scormView}/${scorm.id}`)}>
              <td>{scorm.title}</td>
              <td>
                {scorm.courses.map(each => (
                  <Anchor component={Link} to={`${AdminRoutes.courseView}/${each.id}`}>
                    {courses[each.id] ? courses[each.id].title : 'Unknown'}
                  </Anchor>
                ))}
                {scorm.f2fs.map(each => (
                  <Anchor component={Link} to={`${AdminRoutes.faceToFaceView}/${each.id}`}>
                    {f2fs[each.id] ? f2fs[each.id].name : 'Unknown'}
                  </Anchor>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  scorms: state.scorm.scorms,
  isLoading: state.scorm.isLoading,
  error: state.scorm.error,
  f2fs: state.faceToFace.types,
  courses: state.course.courses
});

const mapDispatchToProps = {
  fetchScorms: fetchScormsRoutine,
  fetchCourses: fetchCoursesRoutine,
  fetchF2FTypes: fetchF2FTypesRoutine
};

export const ScormList = connect(mapStateToProps, mapDispatchToProps)(ScormListComponent);
