import { EmploymentType } from './employment.type.enum';

export const employmentTypeNeedsName: (keyof typeof EmploymentType)[] = [
  'EDUCATION_TEACHING_ENVIRONMENT',
  'EMERGENCY_DEPARTMENT',
  'GENERAL_PRACTICE',
  'OTHER_PRIMARY_CARE',
  'HOSPITAL_SETTING',
  'PRIVATE_PRACTICE',
  'NGO',
  'DRUG_AND_ALCOHOL_SERVICE',
  'COMMUNITY_HEALTH_CENTRE',
  'UNIVERSITY',
  'OTHER_GOVERNMENT_AGENCIES'
];
