/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Skeleton, Anchor, Modal, Switch, Stack, Group, Button } from '@mantine/core';
import { EventEnrolmentDto } from 'types/api/event';
import { UserIndex } from 'types/state';
import {
  CommunityMentalHealthCentre,
  Discipline,
  EmploymentType,
  ExperienceWithEatingDisorder,
  HeadToHealth,
  Headspace,
  NSWHealthLHD,
  PrimaryHealthNetwork
} from 'enums/user-info';
import { Routes } from 'enums';
import { UserDto } from 'types/api/users';
import { IconEye } from '@tabler/icons-react';

type Props = {
  enrolments: EventEnrolmentDto[];
  users: UserIndex;
};

const getServiceName = (user: UserDto): string => {
  if (user.primaryEmployment?.companyName)
    return user.primaryEmployment.companyName;

  if (user.primaryEmployment?.type === 'HEAD_TO_HEALTH')
    return HeadToHealth[user.primaryEmployment.headToHealth];

  if (user.primaryEmployment?.type === 'HEADSPACE')
    return Headspace[user.primaryEmployment.headspace];

  if (user.primaryEmployment?.type === 'HOSPITAL_SETTING')
    return 'Hospital Setting';

  if (user.primaryEmployment?.type === 'PRIMARY_HEALTH_NETWORK')
    return PrimaryHealthNetwork[user.primaryEmployment.headspace];

  if (user.primaryEmployment?.type === 'COMMUNITY_MENTAL_HEALTH_CENTRE')
    return CommunityMentalHealthCentre[
            user.primaryEmployment.communityMentalHealthCentre
          ];
          
  return '';
};

export const PreferencesTableStatic: React.FC<Props> = ({ enrolments, users }) => {
  const [isTableSettingsVisible, setIsTableSettingsVisible] = useState<boolean>(false);

  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [isServiceNameVisible, setIsServiceNameVisible] = useState(false);
  const [isClinicalExperienceVisible, setIsClinicalExperienceVisible] = useState(false);
  const [isEDExperienceVisible, setIsEDExperienceVisible] = useState(false);
  const [isLocationVisible, setIsLocationVisible] = useState(false);

  const handleShowHideBtnClick = useCallback(() => {
    setIsTableSettingsVisible(true);
  }, [])

  const handleTableSettingsModalClose = useCallback(() => {
    setIsTableSettingsVisible(false);
  }, []);

  const handleEmailSwitchToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsEmailVisible(e.currentTarget.checked);
  }, []);
  const handleServiceNameSwitchToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsServiceNameVisible(e.currentTarget.checked);
  }, []);
  const handleClinicalExperienceSwitchToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsClinicalExperienceVisible(e.currentTarget.checked);
  }, []);
  const handleEDExperienceSwitchToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsEDExperienceVisible(e.currentTarget.checked);
  }, []);
  const handleLocationSwitchToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsLocationVisible(e.currentTarget.checked);
  }, []);

  return (
    <>
      <Modal opened={isTableSettingsVisible} onClose={handleTableSettingsModalClose} title="Show  / Hide Table Columns" centered>
        <Stack>
          <Switch label="Email" checked={isEmailVisible} onChange={handleEmailSwitchToggle} />
          <Switch label="Service Name" checked={isServiceNameVisible} onChange={handleServiceNameSwitchToggle} />
          <Switch label="Years of Clinical Experience" checked={isClinicalExperienceVisible} onChange={handleClinicalExperienceSwitchToggle} />
          <Switch label="Years of ED Experience" checked={isEDExperienceVisible} onChange={handleEDExperienceSwitchToggle} />
          <Switch label="Location" checked={isLocationVisible} onChange={handleLocationSwitchToggle} />
        </Stack>
      </Modal>

      <Stack spacing='xs'>
      <Group position='right'>
          <Button variant='outline' color='blue' onClick={handleShowHideBtnClick}>
            <IconEye style={{ marginRight: '0.25rem' }} />
            <span>
              Show / Hide
            </span>
          </Button>
        </Group>

        <Table>
          <thead>
            <tr>
              <th>Priority</th>
              <th>Participant Name</th>
              <th>LHD</th>
              <th>Discipline</th>
              <th>Work Setting</th>
              { isEmailVisible && <th>Email</th> }
              { isServiceNameVisible && <th>Service Name</th> }
              { isClinicalExperienceVisible && <th>Years of Clinical Experience</th> }
              { isEDExperienceVisible && <th>Years of ED Experience</th> }
              { isLocationVisible && <th>Location</th> }
              <th />
            </tr>
          </thead>
          <tbody>
            {enrolments.map(enrolment => {
              const user = users[enrolment.userId];
              return (
                <tr key={enrolment.id}>
                  <td>{typeof enrolment.priority === 'number' ? enrolment.priority + 1 : ''}</td>
                  <td>
                    {!user && <Skeleton h="2rem" />}
                    {user && user.basic && (
                      <Anchor
                        component={Link}
                        to={`${Routes.ExpressionOfInterestViewer}/${user.id}`}
                      >
                        {user.basic.firstName} {user.basic.lastName}
                      </Anchor>
                    )}
                  </td>
                  <td>
                    {user?.nsw?.lhd ? NSWHealthLHD[user.nsw.lhd] : ''}
                  </td>
                  <td>{user && user.discipline ? Discipline[user.discipline.discipline] : <Skeleton h="2rem" />}</td>
                  <td>{user && user.primaryEmployment ? EmploymentType[user.primaryEmployment.type] : <Skeleton h="2rem" />}</td>
                  
                  { isEmailVisible && <td>{user.email}</td> }

                  { isServiceNameVisible &&<td>{user ? getServiceName(user) : <Skeleton h="2rem" />}</td> }

                  { isClinicalExperienceVisible && (
                    <td>
                      {user && user.experience ? (
                        ExperienceWithEatingDisorder[
                          user.experience.yearsInPractice
                        ]
                      ) : (
                        <Skeleton h="2rem" />
                      )}
                    </td>
                  )}

                  { isEDExperienceVisible && (
                    <td>
                      {user && user.experience ? (
                        ExperienceWithEatingDisorder[
                          user.experience.experienceWithEatingDisorder
                        ]
                      ) : (
                        <Skeleton h="2rem" />
                      )}
                    </td>
                  )}

                  { isLocationVisible && (
                    <td>
                      {user && user.basic ? (
                        [user.basic.city]
                      ) : (
                        <Skeleton h="2rem" />
                      )}
                    </td>
                  )}

                  <td>
                    {user ? (
                      <Anchor component={Link} to={`${Routes.ExpressionOfInterestViewer}/${user.id}`}>
                        View Full EOI Form
                      </Anchor>
                    ) : (
                      <Skeleton h="1rem" />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Stack>
    </>
  );
};
