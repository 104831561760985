import React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { Text, Card, Group, Skeleton } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons-react';
import { CourseTaskDto } from 'types/api/course';
import { ScormIndex, SurveyIndex } from 'types/state';

type Props = {
  task: CourseTaskDto;
  onClick: () => void;
  surveys: SurveyIndex;
  scorms: ScormIndex;
};

export const TaskCard: React.FC<Props> = ({ task, onClick, surveys, scorms }) => {
  return (
    <Draggable draggableId={task.id} index={task.sortIndex}>
      {(provided: DraggableProvided) => (
        <Card withBorder {...provided.draggableProps} ref={provided.innerRef} onClick={onClick}>
          <Group>
            <div {...provided.dragHandleProps}>
              <IconGripVertical />
            </div>
            <div>
              <Text>{task.title}</Text>
              {task.type === 'SCORM' && <Text>Module: {scorms[task.scormId] ? scorms[task.scormId].title : <Skeleton h="1rem" />}</Text>}
              {task.type === 'SURVEY' && <Text>Survey: {surveys[task.surveyId] ? surveys[task.surveyId].title : <Skeleton h="1rem" />}</Text>}
            </div>
          </Group>
        </Card>
      )}
    </Draggable>
  );
};
