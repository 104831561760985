import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { RootState, CourseIndex, ScormIndex, SurveyIndex } from 'types/state';
import { fetchCourse as fetchCourseRoutine, dismissCongratulations as dismissCongratulationsRoutine } from 'store/course/routines';
import { fetchScorms as fetchScormsRoutine, openScorm as openScormRoutine, setScormData as setScormDataRoutine } from 'store/scorm/routines';
import { fetchSurveys as fetchSurveysRoutine, answerSurvey as answerSurveyRoutine } from 'store/survey/routines';
import { Container, Text, Title, Card, Box, SimpleGrid, Alert, Group, Button, ThemeIcon, Modal, Center, Image } from '@mantine/core';
import { IconCircle, IconCheck, IconLock } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { CreateSurveyAnswerDto } from 'types/api/survey';
import { ScormCard, SurveyCard } from 'components/TaskCards';
import { Routes } from 'enums';
import { ScormDto } from 'types/api/scorm';
import confetti from 'assets/images/icons/confetti.png';

type Props = {
  courses: CourseIndex;
  fetchCourse: (id: string) => void;
  scorms: ScormIndex;
  fetchScorms: () => void;
  openScorm: (arg0: { scorm: ScormDto; setScormData: (arg1: { key: string; value: string; id: string }) => void }) => void;
  setScormData: (arg0: { key: string; value: string; id: string }) => void;
  surveys: SurveyIndex;
  fetchSurveys: () => void;
  answerSurvey: (dto: CreateSurveyAnswerDto) => void;
  isCongratulations: boolean;
  dismissCongratulations: () => void;
};

const CourseEnrolmentViewComponent: React.FC<Props> = ({ courses, fetchCourse, scorms, fetchScorms, surveys, fetchSurveys, openScorm, setScormData, answerSurvey, isCongratulations, dismissCongratulations }) => {
  const { id } = useParams();

  useEffect(() => {
    fetchCourse(id);
  }, [id]);

  useEffect(() => {
    fetchScorms();
    fetchSurveys();
  }, []);

  const [pageNumber, setPageNumber] = useState(0);

  const course = courses[id];
  if (!course) return null;

  if (!course.enrolment) {
    return (
      <>
        <Box bg="violet" py="4rem" c="white">
          <Container size="lg">
            <Title order={1}>{course.title}</Title>
            <Text mb="md">{course.desc}</Text>
          </Container>
        </Box>
        <Container size="lg">
          <Alert title="You are not enrolled in this course." color="red" my="lg">
            Please <Link to={`${Routes.CourseStore}/${id}`}>click here</Link> to purchase.
          </Alert>
        </Container>
      </>
    );
  }

  if (!course.enrolment.isActive) {
    return (
      <>
        <Box bg="violet" py="4rem" c="white">
          <Container size="lg">
            <Title order={1}>{course.title}</Title>
            <Text mb="md">{course.desc}</Text>
          </Container>
        </Box>
        <Container size="lg">
          <Alert title="Your access to this course has expired." color="red" my="lg">
            Please <Link to={`${Routes.CourseStore}/${id}`}>click here</Link> to renew.
          </Alert>
        </Container>
      </>
    );
  }

  if (!course.taskPages) return null; // FIXME this is just in case you still have the guest version of the course data
  const page = course.taskPages[pageNumber];
  if (!page) return null; // FIXME niche case bit bad

  return (
    <>
      <Box bg="violet" py="4rem" c="white">
        <Container size="lg">
          <Group position="right">
            <Button color="violet" variant="light" uppercase component={Link} to={`${Routes.CourseResources}/${course.id}`}>
              Resources
            </Button>
          </Group>
          <Title order={1} mb="sm">
            {course.title}
          </Title>
          <Text mb="lg">{course.desc}</Text>

          <Text>Your access will expire on {dayjs(course.enrolment.expiresAt).format('DD MMM YYYY')}.</Text>
        </Container>
      </Box>

      <Container size="lg">
        <SimpleGrid cols={course.taskPages.length} mt="-2rem" mb="lg">
          {course.taskPages.map((pg, index) => (
            <Card
              key={pg.id}
              withBorder
              shadow="sm"
              display="flex"
              fw={pageNumber === index ? 'bold' : 'normal'}
              onClick={() => {
                if (pg.status !== 'LOCKED') setPageNumber(index);
              }}
              sx={{ alignItems: 'center' }}
            >
              {pg.status === 'COMPLETE' && (
                <ThemeIcon size="md" radius="lg" color="green" variant="filled">
                  <IconCheck />
                </ThemeIcon>
              )}
              {pg.status === 'INCOMPLETE' && (
                <ThemeIcon size="md" radius="lg" color="cyan" variant="filled">
                  <IconCircle />
                </ThemeIcon>
              )}
              {pg.status === 'LOCKED' && (
                <ThemeIcon size="md" radius="lg" color="gray" variant="light">
                  <IconLock />
                </ThemeIcon>
              )}
              <Text ml="md">{pg.name}</Text>
            </Card>
          ))}
        </SimpleGrid>

        {page.tasks.map(task => {
          if (task.type === 'SCORM') return <ScormCard key={task.id} task={task} scorm={scorms[task.scormId]} openScorm={openScorm} setScormData={setScormData} />;

          if (task.type === 'SURVEY') return <SurveyCard key={task.id} task={task} taskType="COURSE" survey={surveys[task.surveyId]} parentId={id} onSubmit={answerSurvey} />;

          return false;
        })}

        <Group position="right" my="lg">
          <Button disabled={pageNumber === 0} onClick={() => setPageNumber(pageNumber - 1)}>
            Prev
          </Button>
          <Button disabled={page.status !== 'COMPLETE' || pageNumber === course.taskPages.length - 1} onClick={() => setPageNumber(pageNumber + 1)}>
            Next
          </Button>
        </Group>
      </Container>

      {
        course.hasCertificate && (
          <Modal opened={isCongratulations} onClose={() => dismissCongratulations()} centered>
            <Center mb="lg">
              <Image src={confetti} width="8rem" />
            </Center>
            <Title order={2}>Congratulations!</Title>
            <Text>You have successfully completed this training. You can now access your certificate. If you can not access your certificate, please try again in a few minutes.</Text>
            <Button mt="lg" color="red" uppercase component={Link} to={Routes.Certificates}>
              Access Certificate
            </Button>
          </Modal>
        )
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  courses: state.course.courses,
  scorms: state.scorm.scorms,
  surveys: state.survey.surveys,
  isCongratulations: state.course.isCongratulations
});

const mapDispatchToProps = {
  fetchCourse: fetchCourseRoutine,
  fetchScorms: fetchScormsRoutine,
  fetchSurveys: fetchSurveysRoutine,
  openScorm: openScormRoutine,
  setScormData: setScormDataRoutine,
  answerSurvey: answerSurveyRoutine,
  dismissCongratulations: dismissCongratulationsRoutine
};

export const CourseEnrolmentView = connect(mapStateToProps, mapDispatchToProps)(CourseEnrolmentViewComponent);
