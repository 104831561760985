import React from 'react';
import { TextInput, Select, Group, Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { CourseTaskDto, CreateCourseTaskDto, UpdateCourseTaskDto } from 'types/api/course';
import { ScormIndex, SurveyIndex } from 'types/state';

type Props = {
  task: CourseTaskDto;
  surveys: SurveyIndex;
  scorms: ScormIndex;
  create: (dto: CreateCourseTaskDto) => void;
  update: (dto: UpdateCourseTaskDto) => void;
  remove: (taskId: string) => void;
};

export const CourseTaskEditor: React.FC<Props> = ({ task, surveys, scorms, create, update, remove }) => {
  const form = useForm<CreateCourseTaskDto | UpdateCourseTaskDto>({
    initialValues: {
      id: task?.id || undefined,
      title: task?.title || '',
      type: task?.type || 'SCORM',
      scormId: task?.scormId || undefined,
      surveyId: task?.surveyId || undefined
    }
  });

  return (
    <form
      onSubmit={form.onSubmit(values => {
        if (task?.id) update({ ...values, id: task.id });
        else create({ ...values, type: task.type, pageIndex: task.pageIndex });
      })}
    >
      <TextInput mb="lg" label="Short Title" {...form.getInputProps('title')} />

      {task?.type === 'SCORM' && (
        <Select
          my="lg"
          label="Which Module?"
          searchable
          data={Object.values(scorms)
            .sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
            .map(scorm => ({ label: scorm.title, value: scorm.id }))}
          {...form.getInputProps('scormId')}
        />
      )}

      {task?.type === 'SURVEY' && (
        <Select
          my="lg"
          label="Which Survey?"
          searchable
          data={Object.values(surveys)
            .sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
            .map(survey => ({ label: survey.title, value: survey.id }))}
          {...form.getInputProps('surveyId')}
        />
      )}

      <Group mt="lg">
        {task?.id && (
          <Button color="red" onClick={() => remove(task.id)}>
            Delete
          </Button>
        )}
        <Button sx={{ flex: 1 }} type="submit">
          {task?.id ? 'Edit' : 'Create'} Task
        </Button>
      </Group>
    </form>
  );
};
