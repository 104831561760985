import React from 'react';
import { connect } from 'react-redux';
import { EventEnrolmentDto } from 'types/api/event';
import { RootState, UserIndex } from 'types/state';
import { PreferencesTableStatic } from './PreferencesTableStatic';
import { NSWHealthLHD } from 'enums/user-info';

type UnprioritisedPreferencesProps = {
  eventId: string;
  learners: UserIndex;
  allEnrolments: Record<string, EventEnrolmentDto>;
  enrolmentLists: Record<string, string[]>;
  coordinatoreFor: keyof typeof NSWHealthLHD;
};

const UnprioritisedPreferencesComponent: React.FC<UnprioritisedPreferencesProps> = ({
  eventId,
  learners,
  allEnrolments,
  enrolmentLists,
  coordinatoreFor
}) => {
  const enrolments = enrolmentLists[eventId] ? enrolmentLists[eventId].map(i => allEnrolments[i]).filter(e => {
    if (!coordinatoreFor) {
      return e.priority === null;
    }

    const user = learners[e.userId];

    if (!user?.nsw?.lhd) {
      return e.priority === null;
    }

    return e.priority === null && coordinatoreFor !== user.nsw.lhd;
  }) : [];

  return (
    <PreferencesTableStatic enrolments={enrolments} users={learners} />
  );
}

const mapStateToProps = (state: RootState) => ({
  allEnrolments: state.eventEnrolment.enrolments,
  enrolmentLists: state.eventEnrolment.enrolmentLists,
  coordinatoreFor: state.user.coordinatorFor,
});

const mapDispatchToProps = {};

export const UnprioritisedPreferences = connect(mapStateToProps, mapDispatchToProps)(UnprioritisedPreferencesComponent);