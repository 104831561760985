/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Text, Table, Title, Button, Modal, Group, Alert, Stack, Switch } from '@mantine/core';
import { EventDto, EventEnrolmentDto } from 'types/api/event';
import { DragDropContext, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { PreferencesRow } from './PreferencesRow';
import { RootState, UserIndex } from 'types/state';
import dayjs from 'dayjs';
import { UserRoles } from 'enums';
import { NSWHealthLHD } from 'enums/user-info';
import { PreferencesTableStatic } from './PreferencesTableStatic';
import { PreferencesTableAdmin } from './PreferencesTableAdmin';
import { IconDownload, IconEye } from '@tabler/icons-react';
import { getEventPreferencesExport as getEventPreferencesExportRoutine } from 'store/data-export/routines';
import { useParams } from 'react-router-dom';

type Props = {
  event: EventDto;
  learners: UserIndex;
  role: UserRoles;
  coordinatorFor: keyof typeof NSWHealthLHD;
  reorderEnrolment: (arg0: { enrolmentId: string; eventId: string; targetIndex: number }) => void;
  signoffEvent: (eventId: string) => void;
  allEnrolments: Record<string, EventEnrolmentDto>;
  enrolmentLists: Record<string, string[]>;
  getEventPreferencesExport: (id: string) => void;
};

const PreferencesComponent: React.FC<Props> = ({ event, learners, role, coordinatorFor, reorderEnrolment, signoffEvent, allEnrolments, enrolmentLists, getEventPreferencesExport }) => {
  const { id } = useParams();

  const enrolments = enrolmentLists[event.id] ? enrolmentLists[event.id].map(i => allEnrolments[i]).filter(e => learners[e.userId]?.nsw?.lhd === coordinatorFor) : [];

  const [confirm, setConfirm] = useState<boolean>(false);
  const [isTableSettingsVisible, setIsTableSettingsVisible] = useState<boolean>(false);

  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [isServiceNameVisible, setIsServiceNameVisible] = useState(false);
  const [isClinicalExperienceVisible, setIsClinicalExperienceVisible] = useState(false);
  const [isEDExperienceVisible, setIsEDExperienceVisible] = useState(false);
  const [isLocationVisible, setIsLocationVisible] = useState(false);

  const handleDownloadPreferencesClick = useCallback(() => {
    getEventPreferencesExport(id);
  }, [id]);

  const handleShowHideBtnClick = useCallback(() => {
    setIsTableSettingsVisible(true);
  }, [])

  const handleTableSettingsModalClose = useCallback(() => {
    setIsTableSettingsVisible(false);
  }, []);

  const handleEmailSwitchToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsEmailVisible(e.currentTarget.checked);
  }, []);
  const handleServiceNameSwitchToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsServiceNameVisible(e.currentTarget.checked);
  }, []);
  const handleClinicalExperienceSwitchToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsClinicalExperienceVisible(e.currentTarget.checked);
  }, []);
  const handleEDExperienceSwitchToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsEDExperienceVisible(e.currentTarget.checked);
  }, []);
  const handleLocationSwitchToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsLocationVisible(e.currentTarget.checked);
  }, []);

  const onDragEnd = ({ reason, source, destination }) => {
    if (reason === 'CANCEL') return;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;
    if (destination.droppableId === 'unranked') return;
    let moving: EventEnrolmentDto;
    if (source.droppableId === 'ranked') {
      moving = enrolments.find(e => e.priority === source.index);
    } else {
      moving = enrolments.filter(e => e.priority === null)[source.index];
    }
    reorderEnrolment({
      enrolmentId: moving.id,
      targetIndex: destination.index,
      eventId: event.id
    });
  };

  if (role === UserRoles.IOI_ADMIN) return <PreferencesTableAdmin event={event} />;

  if (!event.coordinatorSignoff[coordinatorFor] && (event.status === 'OPEN' || event.status === 'WITH_COORDINATOR'))
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Group my="lg" position="right">
          <Button color="teal" leftIcon={<IconDownload size={20} />} uppercase onClick={handleDownloadPreferencesClick}>
            Download EOI sheet
          </Button>
        </Group>

        <Modal opened={isTableSettingsVisible} onClose={handleTableSettingsModalClose} title="Show  / Hide Table Columns" centered>
          <Stack>
            <Switch label="Email" checked={isEmailVisible} onChange={handleEmailSwitchToggle} />
            <Switch label="Service Name" checked={isServiceNameVisible} onChange={handleServiceNameSwitchToggle} />
            <Switch label="Years of Clinical Experience" checked={isClinicalExperienceVisible} onChange={handleClinicalExperienceSwitchToggle} />
            <Switch label="Years of ED Experience" checked={isEDExperienceVisible} onChange={handleEDExperienceSwitchToggle} />
            <Switch label="Location" checked={isLocationVisible} onChange={handleLocationSwitchToggle} />
          </Stack>
        </Modal>
        
        <Group position='right'>
          <Button variant='outline' color='blue' onClick={handleShowHideBtnClick}>
            <IconEye style={{ marginRight: '0.25rem' }} />
            <span>
              Show / Hide
            </span>
          </Button>
        </Group>

        <Table my="lg">
          <thead>
            <tr>
              <th />
              <th>Priority</th>
              <th>Participant Name</th>
              <th>Discipline</th>
              <th>Work Setting</th>

              { isEmailVisible && <th>Email</th> }
              { isServiceNameVisible && <th>Service Name</th> }
              { isClinicalExperienceVisible && <th>Years of Clinical Experience</th> }
              { isEDExperienceVisible && <th>Years of ED Experience</th> }
              { isLocationVisible && <th>Location</th> }
              
              <th />
            </tr>
          </thead>
          <Droppable droppableId="ranked">
            {(provided: DroppableProvided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {enrolments
                  .filter(e => e.priority !== null)
                  .map(enrolment => (
                    <PreferencesRow 
                      key={enrolment.id}
                      index={enrolment.priority}
                      enrolment={enrolment}
                      user={learners[enrolment.userId]}
                      showEmail={isEmailVisible}
                      showServiceName={isServiceNameVisible}
                      showClinicalExperience={isClinicalExperienceVisible}
                      showEDExperience={isEDExperienceVisible}
                      showLocation={isLocationVisible}
                      isRanked
                    />
                  ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>

        {event.status === 'WITH_COORDINATOR' && (
          <>
            {event.coordinatorSignoff.SYDNEY ? (
              <Text>Your preferences were submitted on {dayjs(event.coordinatorSignoff.SYDNEY.when).format('DD MMM YYYY')}</Text>
            ) : (
              <Button color="cyan" my="lg" ml="auto" size="lg" onClick={() => setConfirm(true)}>
                Submit My Preferences
              </Button>
            )}

            <Modal opened={confirm} title="Confirm Preference Submission" onClose={() => setConfirm(false)} centered>
              <Text>You are about to lock in your preferences, this action cannot be undone.</Text>
              <Text my="md" fw="bold">
                Are you sure?
              </Text>
              <Group position="center">
                <Button
                  color="red"
                  onClick={() => {
                    signoffEvent(event.id);
                    setConfirm(false);
                  }}
                >
                  Submit
                </Button>
                <Button color="gray" onClick={() => setConfirm(false)}>
                  Cancel
                </Button>
              </Group>
            </Modal>
          </>
        )}

        <Title mt="xl">Unprioritised EOIs</Title>
        <Table my="lg">
          <thead>
            <tr>
              <th />
              <th>Participant Name</th>
              <th>Discipline</th>
              <th>Work Setting</th>
              { isEmailVisible && <th>Email</th> }
              { isServiceNameVisible && <th>Service Name</th> }
              { isClinicalExperienceVisible && <th>Years of Clinical Experience</th> }
              { isEDExperienceVisible && <th>Years of ED Experience</th> }
              { isLocationVisible && <th>Location</th> }
              <th />
            </tr>
          </thead>
          <Droppable droppableId="unranked">
            {(provided: DroppableProvided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {enrolments
                  .filter(e => e.priority === null)
                  .map((enrolment, index) => (
                    <PreferencesRow
                      key={enrolment.id}
                      index={index}
                      enrolment={enrolment}
                      user={learners[enrolment.userId]}
                      showEmail={isEmailVisible}
                      showServiceName={isServiceNameVisible}
                      showClinicalExperience={isClinicalExperienceVisible}
                      showEDExperience={isEDExperienceVisible}
                      showLocation={isLocationVisible}
                    />
                  ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    );

  return (
    <>
      {event.coordinatorSignoff[coordinatorFor] && (
        <Alert color="blue" my="lg">
          These preferences were submitted on {dayjs(event.coordinatorSignoff[coordinatorFor].when).format('DD MMM YYYY')}. They can no longer be modified. InsideOut will review and finalize participant list by{' '}
          {dayjs(event.preferencesDue).add(1, 'week').format('DD MMM YYYY')}.
        </Alert>
      )}
      {!event.coordinatorSignoff[coordinatorFor] && (
        <Alert color="yellow" my="lg">
          Preferences were not finalised before the cutoff date of {dayjs(event.preferencesDue).format('DD MMM YYYY')}. All EOIs from your LHD have been passed on to InsideOut for finalisation of participant list. Any
          draft prioritisations were also passed on.
        </Alert>
      )}
      <PreferencesTableStatic enrolments={enrolments} users={learners} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  allEnrolments: state.eventEnrolment.enrolments,
  enrolmentLists: state.eventEnrolment.enrolmentLists
});

const mapDispatchToProps = {
  getEventPreferencesExport: getEventPreferencesExportRoutine
};

export const Preferences = connect(mapStateToProps, mapDispatchToProps)(PreferencesComponent);
