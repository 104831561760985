import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { SimpleGrid, Drawer } from '@mantine/core';
import { RootState, F2FTypeIndex, CourseIndex, ScormIndex, SurveyIndex } from 'types/state';
import { fetchSurveys as fetchSurveysRoutine } from 'store/survey/routines';
import { fetchCourses as fetchCoursesRoutine } from 'store/course/routines';
import { fetchScorms as fetchScormsRoutine } from 'store/scorm/routines';
import { createTask as createTaskRoutine, updateTask as updateTaskRoutine, reorderTask as reorderTaskRoutine, deleteTask as deleteTaskRoutine } from 'store/face-to-face/routines';
import { TaskList } from './TaskList';
import { CourseTaskEditor } from './CourseTaskEditor';
import { SurveyTaskEditor } from './SurveyTaskEditor';
import { ScormTaskEditor } from './ScormTaskEditor';
import { UploadTaskEditor } from './UploadTaskEditor';
import { F2FTaskDto, CreateF2FTaskDto, UpdateF2FTaskDto, F2FTaskReorderDto } from 'types/api/face-to-face';
import { F2FTaskWhen } from 'enums/training';

type Props = {
  id: string;
  types?: F2FTypeIndex;
  surveys?: SurveyIndex;
  courses?: CourseIndex;
  scorms?: ScormIndex;
  fetchSurveys?: () => void;
  fetchCourses?: () => void;
  fetchScorms?: () => void;
  createTask?: (arg0: { typeId: string; dto: CreateF2FTaskDto }) => void;
  updateTask?: (arg0: { typeId: string; dto: UpdateF2FTaskDto }) => void;
  reorderTask?: (arg0: { typeId: string; dto: F2FTaskReorderDto }) => void;
  deleteTask?: (arg0: { typeId: string; taskId: string }) => void;
};

const BuilderComponent: React.FC<Props> = ({ id, types, surveys, courses, scorms, fetchSurveys, fetchCourses, fetchScorms, createTask, reorderTask, updateTask, deleteTask }) => {
  useEffect(() => {
    fetchSurveys();
    fetchCourses();
    fetchScorms();
  }, []);

  const type = types[id];

  const surveyList = Object.values(surveys).sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
  const courseList = Object.values(courses).sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
  const scormList = Object.values(scorms).sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));

  const [task, setTask] = useState<F2FTaskDto>(null);

  const onDragEnd = ({ reason, source, destination }) => {
    if (reason === 'CANCEL') return;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;
    const moving = type[source.droppableId].find((t: F2FTaskDto) => t.sortIndex === source.index);
    reorderTask({
      typeId: id,
      dto: {
        taskId: moving.id,
        targetWhen: destination.droppableId,
        targetIndex: destination.index
      }
    });
  };

  const create = (dto: CreateF2FTaskDto) => {
    createTask({ typeId: id, dto });
    setTask(null);
  };

  const update = (dto: UpdateF2FTaskDto) => {
    updateTask({ typeId: id, dto });
    setTask(null);
  };

  const remove = (taskId: string) => {
    deleteTask({ typeId: id, taskId });
    setTask(null);
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <SimpleGrid cols={3}>
          <TaskList when="BEFORE" tasks={type.BEFORE} setTask={setTask} scorms={scorms} courses={courses} surveys={surveys} />
          <TaskList when="IMMEDIATELY_BEFORE" tasks={type.IMMEDIATELY_BEFORE} setTask={setTask} scorms={scorms} courses={courses} surveys={surveys} />
          <TaskList when="AFTER" tasks={type.AFTER} setTask={setTask} scorms={scorms} courses={courses} surveys={surveys} />
        </SimpleGrid>
      </DragDropContext>

      <Drawer position="right" title={`${task?.id ? 'Edit' : 'Add'} Survey Task ${F2FTaskWhen[task?.when]}`} opened={task?.type === 'SURVEY'} onClose={() => setTask(null)}>
        <SurveyTaskEditor task={task} surveys={surveyList} create={create} update={update} remove={remove} />
      </Drawer>

      <Drawer position="right" title={`${task?.id ? 'Edit' : 'Add'} Course Task ${F2FTaskWhen[task?.when]}`} opened={task?.type === 'COURSE'} onClose={() => setTask(null)}>
        <CourseTaskEditor task={task} courses={courseList} create={create} update={update} remove={remove} />
      </Drawer>

      <Drawer position="right" title={`${task?.id ? 'Edit' : 'Add'} Module Task ${F2FTaskWhen[task?.when]}`} opened={task?.type === 'SCORM'} onClose={() => setTask(null)}>
        <ScormTaskEditor task={task} scorms={scormList} create={create} update={update} remove={remove} />
      </Drawer>

      <Drawer position="right" title={`${task?.id ? 'Edit' : 'Add'} Upload Task ${F2FTaskWhen[task?.when]}`} opened={task?.type === 'UPLOAD'} onClose={() => setTask(null)}>
        <UploadTaskEditor task={task} create={create} update={update} remove={remove} />
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  types: state.faceToFace.types,
  surveys: state.survey.surveys,
  courses: state.course.courses,
  scorms: state.scorm.scorms
});

const mapDispatchToProps = {
  fetchSurveys: fetchSurveysRoutine,
  fetchCourses: fetchCoursesRoutine,
  fetchScorms: fetchScormsRoutine,
  createTask: createTaskRoutine,
  updateTask: updateTaskRoutine,
  reorderTask: reorderTaskRoutine,
  deleteTask: deleteTaskRoutine
};

export const Builder = connect(mapStateToProps, mapDispatchToProps)(BuilderComponent);
