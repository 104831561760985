import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState, CourseIndex } from 'types/state';
import { fetchCourses as fetchCoursesRoutine } from 'store/course/routines';
import { registerAndCheckout as registerAndCheckoutRoutine } from 'store/user/routines';
import { Title, Text, Container, LoadingOverlay, Alert, Box, Anchor, Group, Card, Accordion, Paper, Image, Grid } from '@mantine/core';
import { IconPdf } from '@tabler/icons-react';
import { Carousel } from '@mantine/carousel';
import { GuestLayout } from 'components/GuestLayout';
import { UserRoles } from 'enums';
import { PurchaseCard } from './PurchaseCard';

type Props = {
  userId: string;
  role: UserRoles;
  isAuthenticated: boolean;
  courses: CourseIndex;
  isLoading: boolean;
  error?: string;
  fetchCourses: () => void;
  registerAndCheckout: (arg0: { courseId: string; email: string }) => void;
};

const CourseStoreViewComponent: React.FC<Props> = ({ userId, role, isAuthenticated, courses, isLoading, error, fetchCourses, registerAndCheckout }) => {
  const { id } = useParams();

  useEffect(() => {
    fetchCourses();
  }, []);

  const course = courses[id];
  if (!course) return null;

  return (
    <GuestLayout
      styles={theme => ({
        root: {
          background: theme.colors.red[1]
        }
      })}
    >
      <Container size="md">
        <Grid align="flex-end">
          <Grid.Col span={7} py="8rem">
            <Title order={1} mb="md">
              {course.title}
            </Title>
            <Box color="dimmed">
              <div
                dangerouslySetInnerHTML={{
                  __html: course.purchaseDescription
                }}
              />
            </Box>
          </Grid.Col>

          <Grid.Col span={5}>
            <PurchaseCard userId={userId} isAuthenticated={isAuthenticated} role={role} course={course} registerAndCheckout={registerAndCheckout} />
          </Grid.Col>
        </Grid>
      </Container>

      <Box bg="gray.1">
        <Container size="md" mt="lg" py="4rem">
          {error && (
            <Alert title="Error checking out course" color="red">
              {error}
            </Alert>
          )}

          <Title order={2} mt="4rem" mb="2rem">
            Course Content
          </Title>
          <Paper withBorder mt="2rem" mb="4rem">
            <Accordion>
              {course.purchaseModules.map((mod, index) => (
                <Accordion.Item key={mod.id} value={mod.id}>
                  <Accordion.Control>
                    <Text ml="4rem" fw="bold">
                      Module {index + 1}: {mod.title}
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: mod.description
                      }}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          </Paper>

          <Title order={2} mt="4rem" mb="2rem">
            Discounts
          </Title>
          <div
            dangerouslySetInnerHTML={{
              __html: course.pricingDescription
            }}
          />

          <Title order={2} mt="4rem" mb="2rem">
            Learning Objectives
          </Title>
          <div
            dangerouslySetInnerHTML={{
              __html: course.learningObjectives
            }}
          />

          {course.accreditations.length > 0 && (
            <Title order={2} mt="4rem" mb="2rem">
              Accreditation
            </Title>
          )}
          {course.accreditations.map(acc => (
            <Group key={acc.id} my="md">
              <Image src={acc.logo} width={200} />
              <div>
                <Text fw="bold" mb="xs">
                  {acc.accreditor}
                </Text>
                <Text>{acc.points}</Text>
              </div>
            </Group>
          ))}

          {course.purchaseVideo && <iframe src={course.purchaseVideo} style={{ border: 0 }} width="787" height="576" allow="autoplay; fullscreen; picture-in-picture" title="Course Trailer" />}

          {course.testimonials.length > 0 && (
            <>
              <Title order={2} mt="4rem">
                Testimonials
              </Title>
              <Carousel mx="4rem" my="xl" slideSize="33%" slideGap="lg" loop align="start">
                {course.testimonials.map(test => (
                  <Carousel.Slide key={test.id}>
                    <Card radius="lg" withBorder onClick={e => e.stopPropagation()}>
                      {' '}
                      {/* this onClick is a hack to avoid a bug in mantine carousel */}
                      <Text>&ldquo;{test.text}&rdquo;</Text>
                      <Text fw="bold" mt="xl">
                        - {test.author}
                      </Text>
                    </Card>
                  </Carousel.Slide>
                ))}
              </Carousel>
            </>
          )}

          {course.papers.length > 0 && (
            <Title order={2} mt="8rem" mb="xl">
              References
            </Title>
          )}
          {course.papers.map(paper => (
            <Group key={paper.id} my="md" fz="lg">
              <IconPdf size="3rem" />
              <Anchor href={paper.link}>{paper.title}</Anchor>
            </Group>
          ))}
        </Container>
      </Box>
      <LoadingOverlay visible={isLoading} />
    </GuestLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.id,
  role: state.user.role,
  isAuthenticated: state.user.isAuthenticated,
  courses: state.course.courses,
  isLoading: state.user.isLoading,
  error: state.user.error
});

const mapDispatchToProps = {
  fetchCourses: fetchCoursesRoutine,
  registerAndCheckout: registerAndCheckoutRoutine
};

export const CourseStoreView = connect(mapStateToProps, mapDispatchToProps)(CourseStoreViewComponent);
