import React from 'react';

import { Droppable, DroppableProvided } from 'react-beautiful-dnd';

import { SimpleGrid, Divider, Title, Stack, Button, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { F2FTaskDto } from 'types/api/face-to-face';

import { TaskCard } from './TaskCard';
import { F2FTaskWhen } from 'enums/training';
import { CourseIndex, ScormIndex, SurveyIndex } from 'types/state';

type Props = {
  when: keyof typeof F2FTaskWhen;
  tasks: F2FTaskDto[];
  setTask: (task: F2FTaskDto) => void;
  scorms: ScormIndex;
  courses: CourseIndex;
  surveys: SurveyIndex;
};

export const TaskList: React.FC<Props> = ({ when, tasks, setTask, scorms, courses, surveys }) => {
  if (!tasks) return null;

  return (
    <Stack>
      <Title order={3}>{F2FTaskWhen[when]}</Title>

      <Divider />

      <Droppable droppableId={when}>
        {(provided: DroppableProvided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {tasks.length === 0 && <Text>No tasks.</Text>}
            {tasks.map(task => (
              <TaskCard key={task.id} task={task} scorms={scorms} courses={courses} surveys={surveys} onClick={() => setTask(task)} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Divider />

      <SimpleGrid cols={4}>
        <Button
          leftIcon={<IconPlus />}
          onClick={() =>
            setTask({
              id: undefined,
              sortIndex: undefined,
              title: '',
              type: 'SURVEY',
              when
            })
          }
        >
          Survey
        </Button>
        <Button
          leftIcon={<IconPlus />}
          onClick={() =>
            setTask({
              id: undefined,
              sortIndex: undefined,
              title: '',
              type: 'COURSE',
              when
            })
          }
        >
          Course
        </Button>
        <Button
          leftIcon={<IconPlus />}
          onClick={() =>
            setTask({
              id: undefined,
              sortIndex: undefined,
              title: '',
              type: 'SCORM',
              when
            })
          }
        >
          Module
        </Button>
        <Button
          leftIcon={<IconPlus />}
          onClick={() =>
            setTask({
              id: undefined,
              sortIndex: undefined,
              title: '',
              type: 'UPLOAD',
              when
            })
          }
        >
          Upload
        </Button>
      </SimpleGrid>
    </Stack>
  );
};
