import { Anchor, Group, Navbar, Text, Title, createStyles } from '@mantine/core';
import { IconBook2, IconCalendarEvent, IconChalkboard, IconDatabaseExport, IconMessageCircle, IconPackage, IconPhoto, IconReportAnalytics, IconUser, IconZoomIn, IconZoomQuestion } from '@tabler/icons-react';
import { AdminRoutes, Routes } from 'enums';
import { UserRoles } from 'enums/user-roles';
import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RootState } from 'types/state';

type SidebarItem = {
  text: string;
  icon: React.ReactNode;
  link: AdminRoutes | Routes;
  roles: UserRoles[];
};

type SidebarGroup = {
  header: string;
  items: SidebarItem[];
};

const sidebarGroups: SidebarGroup[] = [
  {
    header: 'Users management',
    items: [
      {
        text: 'Users',
        icon: <IconUser />,
        link: AdminRoutes.users,
        roles: [UserRoles.IOI_ADMIN]
      }
    ]
  },
  {
    header: 'Trainings',
    items: [
      {
        text: 'Face to Face',
        icon: <IconChalkboard />,
        link: AdminRoutes.faceToFaceList,
        roles: [UserRoles.IOI_ADMIN, UserRoles.F2F_FACILITATOR, UserRoles.LHD_COORDINATOR]
      },
      {
        text: 'eLearning',
        icon: <IconBook2 />,
        link: AdminRoutes.courseList,
        roles: [UserRoles.IOI_ADMIN]
      },
      {
        text: 'Events',
        icon: <IconCalendarEvent />,
        link: AdminRoutes.eventList,
        roles: [UserRoles.IOI_ADMIN, UserRoles.F2F_FACILITATOR]
      },
      {
        text: 'Supervisions',
        icon: <IconMessageCircle />,
        link: AdminRoutes.supervisions,
        roles: [UserRoles.IOI_ADMIN]
      },
      {
        text: 'LHD Coordinator View',
        icon: <IconZoomIn />,
        link: Routes.LHD,
        roles: [UserRoles.IOI_ADMIN, UserRoles.F2F_FACILITATOR]
      }
    ]
  },
  {
    header: 'eLibrary',
    items: [
      {
        text: 'Modules',
        icon: <IconPackage />,
        link: AdminRoutes.scormList,
        roles: [UserRoles.IOI_ADMIN]
      },
      {
        text: 'Surveys',
        icon: <IconZoomQuestion />,
        link: AdminRoutes.surveyList,
        roles: [UserRoles.IOI_ADMIN]
      },
      {
        text: 'Media Library',
        icon: <IconPhoto />,
        link: AdminRoutes.fileList,
        roles: [UserRoles.IOI_ADMIN]
      }
    ]
  },
  {
    header: 'Reporting',
    items: [
      {
        text: 'Data export',
        icon: <IconDatabaseExport />,
        link: AdminRoutes.dataExport,
        roles: [UserRoles.IOI_ADMIN]
      },
      {
        text: 'Reports',
        icon: <IconReportAnalytics />,
        link: AdminRoutes.reports,
        roles: [UserRoles.IOI_ADMIN]
      }
    ]
  }
];

const useStyles = createStyles(theme => ({
  navbar: {
    backgroundColor: theme.colors.dark[6]
  },
  header: {
    color: theme.colors.gray[1],
    fontWeight: 'normal',
    margin: '0.5rem'
  },
  section: {
    marginBottom: '1rem'
  },
  link: {
    display: 'block',
    padding: '0.5rem',
    color: theme.colors.gray[1],
    '&.active': {
      color: theme.colors.cyan,
      fontWeight: 'bold'
    },
    '&:hover': {
      background: theme.colors.dark[4],
      textDecoration: 'none'
    }
  }
}));

type Props = {
  role: UserRoles;
};

const SidebarComponent: React.FC<Props> = ({ role }) => {
  const { pathname } = useLocation();
  const { classes, cx } = useStyles();

  return (
    <Navbar width={{ base: 260 }} className={classes.navbar}>
      {sidebarGroups.map(group => (
        <Navbar.Section key={group.header} className={classes.section}>
          <Title order={4} className={classes.header}>
            {group.header}
          </Title>
          {group.items
            .filter(item => item.roles.includes(role))
            .map(item => (
              <Anchor component={Link} key={item.text} to={item.link} className={cx(classes.link, { active: pathname.startsWith(item.link) })}>
                <Group>
                  {item.icon}
                  <Text>{item.text}</Text>
                </Group>
              </Anchor>
            ))}
        </Navbar.Section>
      ))}
    </Navbar>
  );
};

const mapStateToProps = (state: RootState) => ({
  role: state.user.role
});

export const Sidebar = connect(mapStateToProps, null)(SidebarComponent);
