import React from 'react';
import { Link } from 'react-router-dom';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { Anchor, Skeleton } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons-react';
import { EventEnrolmentDto } from 'types/api/event';
import {
  CommunityMentalHealthCentre,
  Discipline,
  EmploymentType,
  ExperienceWithEatingDisorder,
  HeadToHealth,
  Headspace,
  PrimaryHealthNetwork
} from 'enums/user-info';
import { UserDto } from 'types/api/users';
import { Routes } from 'enums';

type Props = {
  enrolment: EventEnrolmentDto;
  index: number;
  user: UserDto;
  isRanked?: boolean;
  showEmail?: boolean;
  showServiceName?: boolean;
  showClinicalExperience?: boolean;
  showEDExperience?: boolean;
  showLocation?: boolean;
};

const getServiceName = (user: UserDto): string => {
  if (user.primaryEmployment?.companyName)
    return user.primaryEmployment.companyName;

  if (user.primaryEmployment?.type === 'HEAD_TO_HEALTH')
    return HeadToHealth[user.primaryEmployment.headToHealth];

  if (user.primaryEmployment?.type === 'HEADSPACE')
    return Headspace[user.primaryEmployment.headspace];

  if (user.primaryEmployment?.type === 'HOSPITAL_SETTING')
    return 'Hospital Setting';

  if (user.primaryEmployment?.type === 'PRIMARY_HEALTH_NETWORK')
    return PrimaryHealthNetwork[user.primaryEmployment.headspace];

  if (user.primaryEmployment?.type === 'COMMUNITY_MENTAL_HEALTH_CENTRE')
    return CommunityMentalHealthCentre[
            user.primaryEmployment.communityMentalHealthCentre
          ];
          
  return '';
};

export const PreferencesRow: React.FC<Props> = ({ enrolment, index, user, isRanked, showEmail, showServiceName, showClinicalExperience, showEDExperience, showLocation }) => {
  return (
    <Draggable draggableId={enrolment.id} index={index}>
      {(provided: DraggableProvided) => (
        <tr {...provided.draggableProps} ref={provided.innerRef}>
          <td {...provided.dragHandleProps}>
            <IconGripVertical />
          </td>
          {isRanked && <td>{index + 1}</td>}
          <td>{user && user.basic ? `${user.basic.firstName} ${user.basic.lastName}` : <Skeleton h="1rem" />}</td>
          <td>{user && user.discipline ? Discipline[user.discipline.discipline] : <Skeleton h="1rem" />}</td>
          <td>{user && user.primaryEmployment ? EmploymentType[user.primaryEmployment.type] : <Skeleton h="1rem" />}</td>

          { showEmail && <td>{user.email}</td> }

          { showServiceName && (
            <td>{user ? getServiceName(user) : <Skeleton h="2rem" />}</td>
          )}

          { showClinicalExperience && (
            <td>
              {user && user.experience ? (
                ExperienceWithEatingDisorder[user.experience.yearsInPractice]
              ) : (
                <Skeleton h="2rem" />
              )}
            </td>
          )}

          { showEDExperience && (
            <td>
              {user && user.experience ? (
                ExperienceWithEatingDisorder[
                  user.experience.experienceWithEatingDisorder
                ]
              ) : (
                <Skeleton h="2rem" />
              )}
            </td>
          )}

          { showLocation && (
            <td>
              {user && user.basic ? [user.basic.city] : <Skeleton h="2rem" />}
            </td>
          )}

          <td>
            {user ? (
              <Anchor component={Link} to={`${Routes.ExpressionOfInterestViewer}/${user.id}`}>
                View Full EOI Form
              </Anchor>
            ) : (
              <Skeleton h="1rem" />
            )}
          </td>
        </tr>
      )}
    </Draggable>
  );
};
