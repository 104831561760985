import React from 'react';
import { connect } from 'react-redux';
import { answerSurvey as answerSurveyRoutine } from 'store/survey/routines';
import { openScorm as openScormRoutine, setScormData as setScormDataRoutine } from 'store/scorm/routines';
import { createUserUpload as createUserUploadRoutine } from 'store/user-upload/routines';
import { F2FTaskDto } from 'types/api/face-to-face';
import { ScormCard, SurveyCard, UploadCard, CourseCard } from 'components/TaskCards';
import { RootState, CourseIndex, ScormIndex, SurveyIndex, UserUploadIndex } from 'types/state';
import { ScormDto } from 'types/api/scorm';
import { CreateSurveyAnswerDto } from 'types/api/survey';

type Props = {
  f2fId: string;
  tasks: F2FTaskDto[];
  scorms?: ScormIndex;
  surveys?: SurveyIndex;
  courses?: CourseIndex;
  uploads?: UserUploadIndex;
  openScorm?: (arg0: { scorm: ScormDto; setScormData: (arg1: { key: string; value: string; id: string }) => void }) => void;
  setScormData?: (arg0: { key: string; value: string; id: string }) => void;
  answerSurvey?: (dto: CreateSurveyAnswerDto) => void;
  createUserUpload?: (arg0: { taskId: string; file: File }) => void;
};

const TaskListComponent: React.FC<Props> = ({ f2fId, tasks, scorms, surveys, courses, uploads, openScorm, setScormData, answerSurvey, createUserUpload }) => {
  return (
    <>
      {tasks.map(task => {
        if (task.type === 'SCORM') return <ScormCard key={task.id} task={task} scorm={scorms[task.scormId]} openScorm={openScorm} setScormData={setScormData} />;

        if (task.type === 'SURVEY') return <SurveyCard key={task.id} task={task} taskType="F2F" parentId={f2fId} survey={surveys[task.surveyId]} onSubmit={answerSurvey} />;

        if (task.type === 'COURSE') return <CourseCard key={task.id} task={task} course={courses[task.courseId]} />;

        if (task.type === 'UPLOAD') return <UploadCard key={task.id} task={task} upload={uploads[task.id] || null} createUserUpload={createUserUpload} />;

        return false;
      })}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  scorms: state.scorm.scorms,
  surveys: state.survey.surveys,
  courses: state.course.courses,
  uploads: state.upload.uploads
});

const mapDispatchToProps = {
  answerSurvey: answerSurveyRoutine,
  openScorm: openScormRoutine,
  setScormData: setScormDataRoutine,
  createUserUpload: createUserUploadRoutine
};

export const TaskList = connect(mapStateToProps, mapDispatchToProps)(TaskListComponent);
