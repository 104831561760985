import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Paper, Title, Text, Box, Group, RingProgress, Button, Progress, Center, Space, Skeleton } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';
import { EventDto, EventEnrolmentDto } from 'types/api/event';
import { NSWHealthLHD } from 'enums/user-info';
import dayjs from 'dayjs';
import { Routes, UserRoles } from 'enums';
import { RootState, UserIndex } from 'types/state';
import { fetchEnrolmentsForEvent as fetchEnrolmentsForEventRoutine } from 'store/event-enrolment/routines';

type Props = {
  event: EventDto;
  role: UserRoles;
  users: UserIndex;
  coordinatorFor: keyof typeof NSWHealthLHD;
  enrolmentLists: Record<string, string[]>;
  allEnrolments: Record<string, EventEnrolmentDto>;
  isLoading: boolean;
  fetchEnrolmentsForEvent: (id: string) => void;
};

const EventCardComponent: React.FC<Props> = ({ event, role, users, coordinatorFor, enrolmentLists, allEnrolments, isLoading, fetchEnrolmentsForEvent }) => {
  const navigate = useNavigate(); // workaround to avoid nesting <a>

  useEffect(() => {
    if (event.id) fetchEnrolmentsForEvent(event.id);
  }, [event.id]);

  if (isLoading || !enrolmentLists[event.id]) {
    return (
      <Paper withBorder shadow="sm" p="1rem">
        <Title order={4}>{event.f2f.name}</Title>
        <Skeleton h="4rem" />
      </Paper>
    );
  }

  const enrolments = enrolmentLists[event.id].map(id => allEnrolments[id]);
  const approvedEnrolments = enrolments.filter((enrolment) => enrolment.status === 'APPROVED' || enrolment.status === 'COMPLETE');
  const attendedEnrolments = approvedEnrolments.filter((enrolment) => enrolment.attendance && Object.values(enrolment.attendance).every((val) => val === true));

  const filteredEnrolments = role === UserRoles.IOI_ADMIN || role === UserRoles.F2F_FACILITATOR ? enrolments : enrolments.filter(e => users[e.userId]?.nsw?.lhd === coordinatorFor);
  const classSizeLimit = role === UserRoles.IOI_ADMIN || role === UserRoles.F2F_FACILITATOR ? event.classSizeLimit : Math.floor(event.classSizeLimit / event.lhds.length);

  const nBEFORE = event.f2f.BEFORE.length;
  const nIMMEDIATELY_BEFORE = event.f2f.IMMEDIATELY_BEFORE.length;
  const nAFTER = event.f2f.AFTER.length;

  const doneBEFORE = filteredEnrolments.filter(e => e.BEFORE === nBEFORE).length;
  const doneIMMEDIATELY_BEFORE = filteredEnrolments.filter(e => e.IMMEDIATELY_BEFORE === nIMMEDIATELY_BEFORE).length;
  const doneAFTER = filteredEnrolments.filter(e => e.AFTER === nAFTER).length;

  return (
    <Paper withBorder shadow="sm" p="1rem" sx={{ display: 'flex', flexDirection: 'column' }} component={Link} to={`${Routes.LHD}/${event.id}`}>
      {event.status === 'WITH_COORDINATOR' && (
        <Button color="orange" variant="light" display="block" ml="auto" mt="-2rem" uppercase onClick={() => navigate(`${Routes.LHD}/${event.id}/preferences`)}>
          Submit Preferences
        </Button>
      )}
      {event.status === 'STARTING_SOON' && (
        <Button color="orange" variant="light" display="block" ml="auto" mt="-2rem" uppercase onClick={() => navigate(`${Routes.LHD}/${event.id}/attendance`)}>
          Print Attendance
        </Button>
      )}

      <Title order={4} display="flex" sx={{ gap: '0.5rem' }}>
        <IconCalendar />
        {dayjs(event.instances[0].startsAt).format('DD MMM')}
        {event.instances.length > 1 && dayjs(event.instances[event.instances.length - 1].startsAt).format(' - DD MMM')}
      </Title>

      <Title order={4} mb="sm">
        {event.f2f.name}
      </Title>

      <Text size="sm" color="gray" mb="lg" lineClamp={2}>
        {event.lhds.map(lhd => NSWHealthLHD[lhd]).join(', ')}
      </Text>

      <Space sx={{ flex: 1 }} />

      {(event.status === 'OPEN' || event.status === 'WITH_COORDINATOR' || event.status === 'WITH_ADMIN') && (
        <Box my="md">
          <Box display="flex">
            <Text sx={{ flex: 1 }}>EOI Applications</Text>
            <Text color="blue">
              {filteredEnrolments.length} / {classSizeLimit}
            </Text>
          </Box>
          <Progress value={(100 * filteredEnrolments.length) / classSizeLimit} />
          {event.status === 'OPEN' && <Text>EOIs are due on {dayjs(event.eoisDue).format('DD MMM YYYY')}</Text>}
          {event.status === 'WITH_COORDINATOR' && <Text color="orange">Preferences are due on {dayjs(event.preferencesDue).format('DD MMM YYYY')}</Text>}
          {event.status === 'WITH_ADMIN' && <Text>Participants will be notified of their confirmation status by InsideOut by {dayjs(event.preferencesDue).add(0, 'week').format('DD MMM YYYY')}</Text>}
        </Box>
      )}

      {(event.status === 'READY' || event.status === 'STARTING_SOON') && (
        <Group>
          <RingProgress
            size={120}
            label={
              <Center>
                <RingProgress
                  size={80}
                  sections={[
                    {
                      value: (100 * doneBEFORE) / approvedEnrolments.length,
                      color: 'blue'
                    }
                  ]}
                />
              </Center>
            }
            sections={[
              {
                value: (100 * doneIMMEDIATELY_BEFORE) / approvedEnrolments.length,
                color: 'teal'
              }
            ]}
          />
          <Box>
            <Text color="blue" fw="bold" sx={{ lineHeight: '1' }}>
              {doneBEFORE} / {approvedEnrolments.length}
            </Text>
            <Text color="blue" size="sm" mb="sm">
              Pre-requisite completed
            </Text>

            <Text color="teal" fw="bold" sx={{ lineHeight: '1' }}>
              {doneIMMEDIATELY_BEFORE} / {approvedEnrolments.length}
            </Text>
            <Text color="teal" size="sm">
              Pre-survey completed
            </Text>
          </Box>
        </Group>
      )}

      {event.status === 'FINISHED' && (
        <Group>
          <RingProgress
            size={120}
            sections={[
              {
                value: (100 * doneAFTER) / attendedEnrolments.length,
                color: 'blue'
              }
            ]}
          />
          <Box>
            <Text color="blue" fw="bold" sx={{ lineHeight: '1' }}>
              {doneAFTER} / {attendedEnrolments.length}
            </Text>
            <Text color="blue" size="sm" mb="sm">
              Post-requisite completed
            </Text>
          </Box>
        </Group>
      )}
    </Paper>
  );
};

const mapStateToProps = (state: RootState) => ({
  allEnrolments: state.eventEnrolment.enrolments,
  enrolmentLists: state.eventEnrolment.enrolmentLists,
  isLoading: state.eventEnrolment.isLoading
});

const mapDispatchToProps = {
  fetchEnrolmentsForEvent: fetchEnrolmentsForEventRoutine
};

export const EventCard = connect(mapStateToProps, mapDispatchToProps)(EventCardComponent);
