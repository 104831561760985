import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchEvent as fetchEventRoutine, reorderEnrolment as reorderEnrolmentRoutine, signoffEvent as signoffEventRoutine } from 'store/event/routines';
import { fetchUsers as fetchUsersRoutine } from 'store/users/routines';
import { fetchEnrolmentsForEvent as fetchEnrolmentsForEventRoutine } from 'store/event-enrolment/routines';
import { Box, Title, Container, Grid, Anchor, Text, Tabs, ActionIcon, Button } from '@mantine/core';
import { IconCalendar, IconClock, IconVideo, IconPin, IconPrinter, IconSortAscending2, IconListCheck, IconArrowLeft } from '@tabler/icons-react';
import { RootState, EventIndex, UserIndex } from 'types/state';
import dayjs from 'dayjs';
import { Promotion } from './Promotion';
import { Preferences } from './Preferences';
import { Attendance } from './Attendance';
import { NSWHealthLHD } from 'enums/user-info';
import { Routes, UserRoles } from 'enums';
import { EventEnrolmentDto } from 'types/api/event';
import { UnprioritisedPreferences } from './UnprioritisedPreferences';

type Props = {
  events: EventIndex;
  fetchEvent: (id: string) => void;
  learners: UserIndex;
  role: UserRoles;
  coordinatorFor: keyof typeof NSWHealthLHD;
  fetchUsers: () => void;
  reorderEnrolment: (arg0: { enrolmentId: string; eventId: string; targetIndex: number }) => void;
  signoffEvent: (eventId: string) => void;
  fetchEnrolmentsForEvent: (id: string) => void;
  allEnrolments: Record<string, EventEnrolmentDto>;
  enrolmentLists: Record<string, string[]>;
};

const CoordinatorEventComponent: React.FC<Props> = ({ events, fetchEvent, learners, role, coordinatorFor, fetchUsers, reorderEnrolment, fetchEnrolmentsForEvent, signoffEvent, allEnrolments, enrolmentLists }) => {
  const { id, tabValue = 'promotion' } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchEvent(id);
    fetchEnrolmentsForEvent(id);
  }, [id]);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const event = events[id];

    if (!event) return;
    fetchEnrolmentsForEvent(id);
  }, [events, id]);

  const event = events[id];

  const enrolments = enrolmentLists[id] ? enrolmentLists[event.id].map(i => allEnrolments[i]) : [];

  if (!event) return null;

  return (
    <Box bg="white" py="4rem">
      <Container size="xl">
        <Button
          mb="xl"
          variant="outline"
          leftIcon={<IconArrowLeft />}
          onClick={() => navigate(Routes.LHD)}
        >
          Back
        </Button>
        <Title order={1} mb="lg">
          {event.name}
        </Title>
        {event.instances.map(instance => (
          <Grid key={instance.id}>
            <Grid.Col span={2} display="flex">
              <IconCalendar />
              <Text ml="md">{dayjs(instance.startsAt).format('DD MMM YYYY')}</Text>
            </Grid.Col>
            <Grid.Col span={3} display="flex">
              <IconClock />
              <Text ml="md">
                {dayjs(instance.startsAt).format('hh:mm a')} - {dayjs(instance.endsAt).format('hh:mm a')}
              </Text>
            </Grid.Col>
            {instance.type === 'ONLINE' && (
              <Grid.Col span={7} display="flex">
                <IconVideo />
                <Anchor ml="md" href={instance.link}>
                  {instance.link}
                </Anchor>
              </Grid.Col>
            )}
            {instance.type === 'IN_PERSON' && (
              <Grid.Col span={7} display="flex">
                <IconPin />
                <Text ml="md">
                  {instance.address} {instance.city} {instance.state}
                </Text>
              </Grid.Col>
            )}
          </Grid>
        ))}

        <Grid my="lg">
          <Grid.Col span={6}>
            <Text size="sm" tt="uppercase" color="gray" fw="bold">
              Local Health District(s)
            </Text>
            <Text>{event.lhds.map(lhd => NSWHealthLHD[lhd]).join(', ')}</Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="sm" tt="uppercase" color="gray" fw="bold">
              Class Size Limit
            </Text>
            <Text>
              {role === 'IOI_ADMIN' ? enrolments.filter(e => e.status === 'APPROVED').length : enrolments.length}
              {' / '}
              {role === 'IOI_ADMIN' ? event.classSizeLimit : Math.floor(event.classSizeLimit / event.lhds.length)}
            </Text>
          </Grid.Col>
        </Grid>

        <Tabs value={tabValue} onTabChange={value => navigate(`${Routes.LHD}/${id}/${value}`)}>
          <Tabs.List>
            <Tabs.Tab value="promotion" icon={<IconPrinter />}>
              Promotion Flyer &amp; EOI Form Link
            </Tabs.Tab>
            <Tabs.Tab value="preferences" icon={<IconSortAscending2 />}>
              Prioritise Your EOIs
            </Tabs.Tab>
            <Tabs.Tab value="unprioritised-preferences" icon={<IconSortAscending2 />}>
              Non-Prioritisable EOIs
            </Tabs.Tab>
            <Tabs.Tab value="attendance" icon={<IconListCheck />}>
              Participant List, Progress &amp; Attendance Sheet
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="promotion" my="lg">
            <Promotion event={event} />
          </Tabs.Panel>

          <Tabs.Panel value="preferences" my="lg">
            <Preferences event={event} learners={learners} role={role} coordinatorFor={coordinatorFor} reorderEnrolment={reorderEnrolment} signoffEvent={signoffEvent} />
          </Tabs.Panel>

          <Tabs.Panel value="unprioritised-preferences" my="lg">
            <UnprioritisedPreferences eventId={event.id} learners={learners} />
          </Tabs.Panel>

          <Tabs.Panel value="attendance" my="lg">
            <Attendance event={event} />
          </Tabs.Panel>
        </Tabs>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  events: state.event.events,
  learners: state.users.LEARNER,
  role: state.user.role,
  coordinatorFor: state.user.coordinatorFor,
  allEnrolments: state.eventEnrolment.enrolments,
  enrolmentLists: state.eventEnrolment.enrolmentLists
});

const mapDispatchToProps = {
  fetchEvent: fetchEventRoutine,
  fetchUsers: fetchUsersRoutine,
  reorderEnrolment: reorderEnrolmentRoutine,
  signoffEvent: signoffEventRoutine,
  fetchEnrolmentsForEvent: fetchEnrolmentsForEventRoutine
};

export const CoordinatorEvent = connect(mapStateToProps, mapDispatchToProps)(CoordinatorEventComponent);
