import React from 'react';
import { TextInput, Group, Button, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { F2FTaskDto, CreateF2FTaskDto, UpdateF2FTaskDto } from 'types/api/face-to-face';
import { ScormDto } from 'types/api/scorm';
import { F2FTaskType } from 'enums/training';

type Props = {
  task: F2FTaskDto;
  scorms: ScormDto[];
  create: (dto: CreateF2FTaskDto) => void;
  update: (dto: UpdateF2FTaskDto) => void;
  remove: (id: string) => void;
};

export const ScormTaskEditor: React.FC<Props> = ({ task, scorms, create, update, remove }) => {
  const form = useForm({
    initialValues: {
      title: task?.title || '',
      scormId: task?.scormId || null
    },
    validate: {
      title: value => (value.length === 0 ? 'A short title is required' : null),
      scormId: value => (value ? null : 'Please select a module to add')
    },
    transformValues: values => ({
      id: task.id,
      title: values.title,
      type: 'SCORM' as keyof typeof F2FTaskType,
      when: task.when,
      scormId: values.scormId
    })
  });
  return (
    <form
      onSubmit={form.onSubmit(values => {
        if (task.id) update(values);
        else create(values);
      })}
    >
      <TextInput mb="lg" label="Short Title" {...form.getInputProps('title')} />

      <Select my="lg" label="Which Module?" searchable data={scorms.map(scorm => ({ value: scorm.id, label: scorm.title }))} {...form.getInputProps('scormId')} />
      <Group position="right" mt="lg">
        {task?.id && (
          <Button color="red" onClick={() => remove(task.id)}>
            Delete Task
          </Button>
        )}
        <Button type="submit">{task?.id ? 'Save' : 'Create'}</Button>
      </Group>
    </form>
  );
};
