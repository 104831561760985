import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCourses as fetchCoursesRoutine } from 'store/course/routines';
import { fetchEvents as fetchEventsRoutine } from 'store/event/routines';
import { getEventCertificate as getEventCertificateRoutine, getCourseCertificate as getCourseCertificateRoutine } from 'store/certificate/routines';
import { Box, Title, Text, Container, SimpleGrid, Card, ThemeIcon, Alert, LoadingOverlay } from '@mantine/core';
import { IconCertificate } from '@tabler/icons-react';
import { CourseIndex, EventIndex, RootState } from 'types/state';
import dayjs from 'dayjs';

type Props = {
  courses: CourseIndex;
  fetchCourses: () => void;
  events: EventIndex;
  fetchEvents: () => void;
  getEventCertificate: (id: string) => void;
  getCourseCertificate: (id: string) => void;
  certificateLoading: { [id: string]: boolean };
  certificateError: { [id: string]: string };
};

const CertificatesComponent: React.FC<Props> = ({ courses, fetchCourses, events, fetchEvents, getEventCertificate, getCourseCertificate, certificateLoading, certificateError }) => {
  useEffect(() => {
    fetchCourses();
    fetchEvents();
  }, []);

  return (
    <>
      <Box bg="cyan">
        <Container size="lg" c="white" py="4rem">
          <Title order={1}>Certificates</Title>
        </Container>
      </Box>
      <Container size="lg" my="xl">
        <SimpleGrid cols={3}>
          {Object.values(events)
            .filter(e => e.enrolment?.status === 'COMPLETE')
            .map(event => (
              <Card key={event.id} display="flex" sx={{ alignItems: 'center' }} withBorder onClick={() => getEventCertificate(event.id)} pos="relative">
                <ThemeIcon size="xl" variant="light" color="green">
                  <IconCertificate />
                </ThemeIcon>
                <Box ml="lg">
                  <Title order={4}>{event.name}</Title>
                  <Text color="dimmed">
                    {dayjs(event.instances[0].startsAt).format('DD MMM YYYY')}
                    {event.instances.length > 0 && dayjs(event.instances[event.instances.length - 1].startsAt).format(' - DD MMM YYYY')}
                  </Text>
                </Box>
                <LoadingOverlay visible={certificateLoading[event.id]} />
                {certificateError[event.id] && (
                  <Alert color="red" title="Something went wrong">
                    {certificateError[event.id]}
                  </Alert>
                )}
              </Card>
            ))}
          {Object.values(courses)
            .filter(c => c.enrolment?.completed)
            .map(course => (
              <Card key={course.id} display="flex" sx={{ alignItems: 'center' }} withBorder onClick={() => getCourseCertificate(course.id)} pos="relative">
                <ThemeIcon size="xl" variant="light" color="green">
                  <IconCertificate />
                </ThemeIcon>
                <Box ml="lg">
                  <Title order={4}>{course.title}</Title>
                  <Text color="dimmed">{dayjs(course.enrolment.completed).format('DD MMM YYYY')}</Text>
                </Box>
                <LoadingOverlay visible={certificateLoading[course.id]} />
                {certificateError[course.id] && (
                  <Alert color="red" title="Something went wrong">
                    {certificateError[course.id]}
                  </Alert>
                )}
              </Card>
            ))}
        </SimpleGrid>
      </Container>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  courses: state.course.courses,
  events: state.event.events,
  certificateLoading: state.certificate.loading,
  certificateError: state.certificate.error
});

const mapDispatchToProps = {
  fetchCourses: fetchCoursesRoutine,
  fetchEvents: fetchEventsRoutine,
  getEventCertificate: getEventCertificateRoutine,
  getCourseCertificate: getCourseCertificateRoutine
};

export const Certificates = connect(mapStateToProps, mapDispatchToProps)(CertificatesComponent);
