import React from 'react';
import { CourseDto, CourseTaskDto, CourseTaskPageDto } from 'types/api/course';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { Button, Card, Text, Group, ActionIcon, TextInput, Space } from '@mantine/core';
import { IconPencil, IconX } from '@tabler/icons-react';
import { TaskCard } from './TaskCard';
import { ScormIndex, SurveyIndex } from 'types/state';
import { UseFormReturnType } from '@mantine/form';

type Props = {
  page: CourseTaskPageDto;
  setEditTask: (task: CourseTaskDto) => void;
  surveys: SurveyIndex;
  scorms: ScormIndex;
  form: UseFormReturnType<Partial<CourseDto>>;
  editingTitle: string;
  setEditingTitle: (id: string) => void;
};

export const CourseTaskPage: React.FC<Props> = ({ page, setEditTask, surveys, scorms, form, editingTitle, setEditingTitle }) => {
  return (
    <Droppable droppableId={page.id}>
      {(provided: DroppableProvided) => (
        <Card withBorder shadow="sm" my="lg" {...provided.droppableProps} ref={provided.innerRef}>
          <Group mb="lg">
            {editingTitle === page.id && (
              <Group>
                <ActionIcon onClick={() => setEditingTitle(null)}>
                  <IconX />
                </ActionIcon>
                <TextInput {...form.getInputProps(`pageTitles.${page.id}`)} />
                <Button type="submit">Save</Button>
              </Group>
            )}
            {editingTitle !== page.id && (
              <Group>
                <ActionIcon onClick={() => setEditingTitle(page.id)}>
                  <IconPencil />
                </ActionIcon>
                <Text>{page.name}</Text>
              </Group>
            )}

            <Space sx={{ flex: 1 }} />

            <Button
              onClick={() =>
                setEditTask({
                  pageIndex: page.id,
                  title: '',
                  type: 'SCORM'
                })
              }
            >
              Add Module
            </Button>

            <Button
              onClick={() =>
                setEditTask({
                  pageIndex: page.id,
                  title: '',
                  type: 'SURVEY'
                })
              }
            >
              Add Survey
            </Button>
          </Group>

          {page.tasks.map(task => (
            <TaskCard key={task.id} task={task} onClick={() => setEditTask(task)} scorms={scorms} surveys={surveys} />
          ))}

          {provided.placeholder}
        </Card>
      )}
    </Droppable>
  );
};
