/* eslint-disable no-param-reassign */
// immer allows us to safely reassign params
import { Routine } from 'redux-saga-routines';
import { produce } from 'immer';
import { createScorm, reuploadScorm, fetchScorm, fetchScorms, setScormData, openScorm, uploadSplash, updateScormMetadata } from './routines';
import { ScormIndex, ScormState } from 'types/state';
import { ScormDto } from 'types/api/scorm';
import { ScormDataType } from 'enums/scorm';

const initialState: ScormState = {
  scorms: {},
  isLoading: false,
  error: undefined
};

export const scormReducer = produce((state: ScormState, { type, payload }: Routine<any> = {}) => {
  switch (type) {
    case createScorm.TRIGGER:
    case reuploadScorm.TRIGGER:
    case fetchScorms.TRIGGER:
    case uploadSplash.TRIGGER:
    case updateScormMetadata.TRIGGER:
      state.isLoading = true;
      state.error = undefined;
      break;
    case fetchScorm.TRIGGER:
      if (state.scorms[payload]) state.scorms[payload].isLoading = true;
      state.error = undefined;
      break;
    case openScorm.TRIGGER:
      state.scorms[payload.scorm.id].isLoading = true;
      state.error = undefined;
      break;
    case setScormData.TRIGGER:
      // Need to optimistically set local values in case
      // they are quickly re-requested by scorm viewer
      state.isLoading = true;
      state.error = undefined;
      switch (payload.key) {
        case ScormDataType.SUSPEND_DATA:
          state.scorms[payload.id].enrolment.data = payload.value;
          break;
        case ScormDataType.LESSON_STATUS:
          state.scorms[payload.id].enrolment.status = payload.value;
          break;
        case ScormDataType.LOCATION:
          state.scorms[payload.id].enrolment.location = payload.value;
          break;
        // no default
      }
      break;
    case fetchScorms.SUCCESS:
      state.isLoading = false;
      state.scorms = payload.reduce(
        (acc: ScormIndex, scorm: ScormDto) => ({
          ...acc,
          [scorm.id]: scorm
        }),
        {}
      );
      break;
    case createScorm.SUCCESS:
    case reuploadScorm.SUCCESS:
    case fetchScorm.SUCCESS:
    case openScorm.SUCCESS:
      state.isLoading = false;
      state.scorms[payload.id] = payload;
      break;
    case uploadSplash.SUCCESS:
      state.isLoading = false;
      state.scorms[payload.scormId].splash = payload.splash;
      break;
    case updateScormMetadata.SUCCESS:
      state.isLoading = false;
      Object.apply(state.scorms[payload.id], payload);
      break;
    case createScorm.FAILURE:
    case reuploadScorm.FAILURE:
    case fetchScorm.FAILURE:
    case fetchScorms.FAILURE:
    case setScormData.FAILURE:
    case uploadSplash.FAILURE:
    case updateScormMetadata.FAILURE:
      state.isLoading = false;
      state.error = payload;
      break;
    // no default
  }
}, initialState);
