import Routing from 'containers/AppRoutes';
import React from 'react';
import { Provider } from 'react-redux';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { store } from 'store';
import { GlobalStyle } from '../../styles';
import TokenVerifier from '../TokenVerifier';
import { theme } from 'styles/mantine-theme';
import { zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';

zxcvbnOptions.setOptions({
  translations: zxcvbnEnPackage.translations,
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary
  }
});

const App: React.FC = () => (
  <Provider store={store}>
    <MantineProvider theme={theme}>
      <GlobalStyle />
      <TokenVerifier>
        <Routing />
      </TokenVerifier>
      <Notifications />
    </MantineProvider>
  </Provider>
);

export default App;
